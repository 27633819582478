<template>
  <v-row dense>
    <v-col>
      <v-card elevation="0" outlined>
        <v-card-title>메시지 템플릿 관리</v-card-title>
        <v-card-text>
          <v-data-table disable-filtering disable-sort single-select dense
                        :items="items" :headers="headers"
                        :loading="loading" item-key="_id"
                        mobile-breakpoint="0" :footer-props="vs.footerPropsErsTable"
                        @click:row="selectItem"
          >
            <template v-slot:item.classCode="{item}">
              {{(classCodeOptions.find(e => e.value === item.classCode) || {text: item.classCode}).text}}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-row>
        <v-col>
          <v-card elevation="0" outlined width="40%"> <!-- detail -->
            <v-card-subtitle><v-btn small color="warning" @click.stop="setCreateMode">신규등록</v-btn></v-card-subtitle>
            <v-card-text>
              <v-row dense>
                <v-col><v-select dense v-model="selected.classCode" :items="classCodeOptions" label="구분" /></v-col>
              </v-row>
              <v-row dense>
                <v-col><v-text-field dense v-model="selected.text" label="메세지 내용" /></v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn small color="info" @click.stop="saveItem" :disabled="!selected.text">저장</v-btn>
              <v-btn small color="error" @click.stop="deleteItem" :disabled="!selected._id">삭제</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import api from '@/services/api'
import vs from '@/utils/viewSupport'
import { mapGetters } from 'vuex'
import notify from '@/utils/notify'

export default {
  name: 'Workgroup',
  metaInfo: {
    title: '워킹그룹 관리'
  },
  data() {
    return {
      vs,
      loading: false,
      items: [],
      selected: {
      },
      headers: [
        { text: '구분', value: 'classCode' },
        { text: '메세지 내용', value: 'text' },
        { text: '등록일', value: 'createdAt' },
        { text: '수정일', value: 'updatedAt' },
        { text: '등록자', value: 'regId' },
        { text: '수정자', value: 'updId' }
      ],
      classCodeOptions: [
        { text: '공통', value: 'common' },
        { text: '출동접수', value: 'receipt' },
        { text: '출동결과', value: 'result' }
      ],
      mode: 'V'
    }
  },
  methods: {
    list() {
      this.loading = true
      api.getMessageTemplateAll().then(r => { this.items = r.result }).finally(() => { this.loading = false })
    },
    selectItem(item) {
      this.selected = item
      this.mode = 'E'
    },
    setCreateMode() {
      this.selected = { classCode: 'common' }
      this.mode = 'C'
    },
    saveItem() {
      if (this.selected) {
        if (this.isCreateMode) {
          api.postMessageTemplate(this.selected).then(r => {
            notify.showMessage('등록 되었습니다.')
            this.list()
            this.setCreateMode()
          })
        } else {
          api.putMessageTemplate(this.selected._id, this.selected).then(r => {
            notify.showMessage('수정 되었습니다.')
            this.list()
            this.setCreateMode()
          })
        }
      }
    },
    deleteItem() {
      if (this.selected) {
        api.deleteMessageTemplate(this.selected._id).then(() => {
          notify.showMessage('삭제 되었습니다.')
          this.list()
          this.setCreateMode()
        })
      }
    },
  },
  mounted() {
    this.list()
    this.setCreateMode()
  },
  created() {
    this.$store.commit('setAppBarTitle', '메시지 템플릿 관리')
  },
  computed: {
    ...mapGetters(['loggedInUser', 'role']),
    isViewMode() {
      return this.mode === 'V'
    },
    isCreateMode() {
      return this.mode === 'C'
    },
    isEditMode() {
      return this.mode === 'E'
    },
    isManager() {
      return this.role === 'CD'
    }
  }
}
</script>

<style scoped>
.v-data-table >>> thead tr th {
  padding: 6px !important;
  font-weight: bold !important;
  background-color: black !important;
  color: white !important;
  font-size: 12px !important;
}

.v-data-table >>> tbody tr td {
  padding: 0px 0px 0px 0px !important;
  font-size: 11px !important;
}

>>> div.v-select__selections > div {
  font-size:14px !important;
}

</style>
